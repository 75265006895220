import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// project imports
import config from './../config';
import { Redirect, Route } from 'react-router-dom/cjs/react-router-dom.min';
import Loadable from '../components/Loadable';
import { ROUTES } from '../constants';

//-----------------------|| ROUTING RENDER ||-----------------------//
const ErrorScreen = Loadable(lazy(() => import('../views/error')));

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={config.defaultPath} />
      <Route exact path={Object.values(ROUTES)}>
          {/* Routes for authentication pages */}
          <AuthenticationRoutes />

          {/* Routes for main layouts */}
          <MainRoutes />
      </Route>
      <Route path="*" component={ErrorScreen} />
    </Switch>
  );
};

export default Routes;

export const ja = {
    appName: '蓄電池管理システム',
    login: 'ログイン',
    email: 'メールアドレス',
    loginByGmail: 'Gmailでログイン',
    logout: 'ログアウト',
    changePassword: 'パスワード変更',
    battery: '蓄電池',
    batteryPlant: '蓄電所',
    batteryPlantList: '蓄電所一覧',
    solar: '太陽光',
    solarPlant: '太陽光発電所',
    solarPlantList: '太陽光発電所一覧',
    deleteThisRecord: 'このレコードを削除しますか？',
    areYouSure: 'ご確認ください',
    id: 'ID',
    name: '名',
    address: '住所',
    addBatteryPlant: '新規蓄電所追加',
    search: '検索',
    addNew: '新規追加',
    batteryPlantSearchHint: '蓄電所名',
    saveDataSuccess: 'データー保存が完了しました',
    deleteDataSuccess: 'データー削除が完了しました',
    save: '保存',
    edit: '編集',
    delete: '削除',
    oppsSomethingWentWrong: 'Opps! Something went wrong!',
    charge: '充電',
    uncharge: '放電',
    start: '起動',
    stop: '停止',
    manualMode: '手動モード',
    frame: 'コマ',
    from: '開始',
    to: '終了',
    action: 'アクション',
    volume: '容量',
    vtn: 'VTN',
    selectDate: '日付選択',
    layout: {
        title: '蓄電池レポーティングシステム',
        realtimeInfo: 'リアルタイム情報',
        batteryStations: '蓄電所一覧',
        report: 'レポート',
        logout: 'ログアウト',
        TVShopping: 'テレビショッピング研究所',
        changePassword: 'パスワード変更'
    },
    dashboard: {
        basicInformation: {
            address: '住所',
            operationStartDate: '運用開始日',
            marketArea: '市場エリア',
            report: '月次レポート'
        },
        condition: '充放電状況',
        JEPXTransactionAmount: 'JEPX取引額',
        marketTransactionAmount: '充放電量',
        JEPXTooltip: 'JPEX',
        marketTransactionTooltip: '需給調整市場',
        detailInfo: '充放電量の詳細情報 (30分毎)'
    },
    batteryStation: {
        list: {
            label: {
                id: '蓄電所ID',
                name: '蓄電所名',
                address: '住所',
                createAt: '運用開始日',
                marketArea: '市場エリア'
            }
        }
    },
    report: {
        list: {
            label: {
                download: 'ダウンロード',
                fileName: 'タイトル',
                createdAt: '登録日',
                action: 'アクション',
                month: '年月',
                delete: '削除'
            }
        },
        upload: 'アップロード',
        uploadDialog: {
            title: 'レポートアップロード',
            fileName: 'タイトル',
            cancel: 'キャンセル',
            upload: 'アップロード',
            file: 'ファイル',
            chooseFile: '選択',
            pickMonth: '年月'
        },
        confirmUploadDialog: {
            title: 'ご確認ください',
            content: 'ファイルをアップロードしてもよろしいですか。'
        },
        confirmDeleteDialog: {
            title: 'ご確認ください',
            content: 'ファイルを削除してもよろしいですか。',
            cancel: 'キャンセル',
            delete: '削除'
        }
    },
    resetPassword: {
        returnLogin: 'ログイン画面に戻る',
        sendForgotPassword: '送信する'
    },
    error: {
        internetDisconnected: 'ネットワークエラーが起きました。\nネットワークを確認し、ブラウザでページの更新をしてください。',
    },
};
export const SET_MENU = '@ui/SET_MENU';
export const MENU_OPEN = '@ui/MENU_OPEN';
export const TOGGLE_MESSAGE = '@ui/TOGGLE_MESSAGE';
export const TOGGLE_MESSAGE_CLOSE = '@ui/TOGGLE_MESSAGE_CLOSE';
export const TOGGLE_LOADING = '@ui/TOGGLE_LOADING';
export const TOGGLE_IS_OFFLINE = '@ui/TOGGLE_IS_OFFLINE';

const initialState = {
  customization: {
    isOpen: [],
    opened: true
  },
  snackbar: {
    open: false,
    severity: null,
    message: null
  },
  loading: false,
  isOffline: false,
}

export function toggleGlobalMessage (open, severity, message) {
  return (dispatch) => dispatch({
    type: TOGGLE_MESSAGE,
    data: {
      open,
      severity,
      message
    }
  })
}

export function toggleGlobalMessageClose () {
  return (dispatch) => dispatch({
    type: TOGGLE_MESSAGE_CLOSE,
  })
}

export function toggleLoading (loading) {
  return (dispatch) => dispatch({
    type: TOGGLE_LOADING,
    loading
  })
}

export function toggleIsOffline (isOffline) {
  return (dispatch) => dispatch({
    type: TOGGLE_IS_OFFLINE,
    isOffline
  })
}

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case MENU_OPEN:
      const id = action.id;
      return {
        ...state,
        customization: {
          ...state.customization,
          isOpen: [id]
        }
      };
    case SET_MENU:
      return {
        ...state,
        customization: {
          ...state.customization,
          opened: action.opened
        }
      };
    case TOGGLE_MESSAGE:
      return {
        ...state,
        snackbar: action.data
      }
    case TOGGLE_MESSAGE_CLOSE:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false
        }
      }
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading
      }
      case TOGGLE_IS_OFFLINE:
        return {
          ...state,
          isOffline: action.isOffline
        }
    default:
      return state;
  }
};
export const ROUTES = {
  ERROR: '/error',
  LOGIN: '/auth/login',
  LOGOUT: '/logout',
  DASHBOARD: '/battery-stations/:batteryStationId/dashboard',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  BATTERY_STATIONS: '/battery-stations',
  REPORT: '/battery-stations/:batteryStationId/report'
};

export const ROUTE_NAMES = {
  LOGIN: 'ログイン',
  FORGOT_PASSWORD: 'パスワード再設定',
  RESET_PASSWORD: 'パスワード再設定',
  BATTERY_STATIONS: '蓄電所一覧',
  DASHBOARD: 'リアルタイム情報',
  REPORT: 'レポート',
  CHANGE_PASSWORD: 'パスワード変更'
};

export const PUBLIC_ROUTES = [ROUTES.ERROR, ROUTES.LOGIN];

export const AUTH_SKIP_ROUTES = [ROUTES.LOGIN];

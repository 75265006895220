import React, { lazy, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import { ROUTES, ROUTE_NAMES } from '../constants';
import { connect } from 'react-redux';
import { toggleLoading } from '../redux/_ui.redux';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard')));
const ChangePasswordScreen = Loadable(lazy(() => import('../views/change-password')));
const BatteryStationsScreen = Loadable(lazy(() => import('../views/battery-stations')));
const ReportScreen = Loadable(lazy(() => import('../views/report')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = ({ toggleLoading = () => {} }) => {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const currentRoute = Object.entries(ROUTES).find(([key, val]) =>
      key === 'DASHBOARD' || key === 'REPORT' ? pathname.endsWith(val.split('/').pop()) : val === pathname
    );

    document.title = currentRoute ? `${ROUTE_NAMES[currentRoute[0]]}｜蓄電池レポーティングシステム` : '蓄電池レポーティングシステム';
  }, [location, location.pathname]);

  useEffect(() => {
    toggleLoading(true);
  }, [toggleLoading]);

  return (
    <Route exact path={[ROUTES.DASHBOARD, ROUTES.REPORT, ROUTES.CHANGE_PASSWORD, ROUTES.BATTERY_STATIONS]}>
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          <Route exact path={ROUTES.DASHBOARD} component={DashboardDefault} />
          <Route exact path={ROUTES.REPORT} component={ReportScreen} />
          <Route exact path={ROUTES.CHANGE_PASSWORD} component={ChangePasswordScreen} />
          <Route exact path={ROUTES.BATTERY_STATIONS} component={BatteryStationsScreen} />
        </Switch>
      </MainLayout>
    </Route>
  );
};

const mapState = (state) => ({});

const mapDispatch = {
  toggleLoading: toggleLoading
};

export default connect(mapState, mapDispatch)(MainRoutes);

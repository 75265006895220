import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

// material-ui
import { makeStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import { LANG } from '../../../themes';

// project imports
import background from '../../../assets/images/background.jpg';
import { ROUTES } from '../../../constants';
import { ArrowDropDown, Menu as MenuIcon, Settings } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Sidebar from '../Sidebar';
import { useEffect } from 'react';
import { ApiFactory } from '../../../api/Api';
import { useState } from 'react';
import CustomSnackbar from '../../../components/CustomSnackbar';
import { toggleLoading } from '../../../redux/_ui.redux';
import { connect } from 'react-redux';
import { JwtHelper } from '../../../helpers/jwt';

// style constant
const useStyles = makeStyles()((theme) => ({
  header: {
    backgroundImage: `url("${background}")`,
    backgroundPositionY: 'center',
    paddingTop: '2.5rem',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '1rem',
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  },
  title: {
    textDecoration: 'underline',
    textDecorationColor: '#747989',
    textUnderlineOffset: '8px',
    color: 'white',
    fontSize: '1.75em',
    fontWeight: '500',
    marginBlockStart: '0',
    marginInlineStart: '2rem',
    marginBlockEnd: '0rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      marginInlineStart: '0.5rem'
    }
  },
  subtitle: {
    color: 'white',
    textDecoration: 'none',
    minWidth: '25rem',
    fontSize: '0.9rem',
    [theme.breakpoints.down('md')]: {
      minWidth: '17rem',
      fontSize: '12px',
      paddingTop: '5px'
    }
  },
  subHeader: {
    display: 'flex',
    marginLeft: '2rem',
    height: '2.5rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0.5rem',
      height: '2rem'
    }
  },
  menuHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#2F3D50',
    paddingRight: '8%',
    color: 'white',
    paddingTop: '1.125rem',
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '0',
      paddingRight: '0',
      display: 'none'
    }
  },
  triangle: {
    width: '0',
    height: '0',
    borderTop: '40px solid transparent',
    borderRight: '30px solid #2F3D50',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  break1: {
    height: '1.25rem',
    backgroundColor: '#2F3D50',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  break2: {
    marginTop: '2px',
    height: '1rem',
    backgroundColor: '#025485'
  },
  buttonMenu: {
    color: 'white',
    borderColor: '#818A95',
    padding: '0 20px',
    height: 'fit-content',
    '&:hover': {
      backgroundColor: '#3c6485'
    },
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 10px',
      fontSize: '14px'
    }
  },
  buttonMenuActive: {
    textUnderlineOffset: '5px',
    textDecoration: 'underline',
    textDecorationColor: '#15ABFF',
    textDecorationThickness: '2.5px',
    '&.Mui-disabled': {
      color: '#ffffff'
    }
  },
  userName: {
    color: 'white',
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  userMobile: {
    color: 'white',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      fontSize: '1.5rem',
      fontWeight: 700,
      alignItems: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  userMenu: {},
  displayDashboardButton: {
    display: 'block'
  },
  hideDashboardButton: {
    display: 'none'
  },
  menuButton: {
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  menuButtonMobile: {
    color: 'white',
    display: 'none',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'white',
    marginRight: '10px',
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    }
  },
  headerRightMobile: {},
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ userName, toggleLoading = () => {} }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { batteryStationId } = useParams();
  const [defaultBatteryStationId, setDefaultBatteryStationId] = useState();
  const handleLogout = () => {
    localStorage.removeItem('user');
    history.push(ROUTES.LOGIN);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClickMenuButton = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirectChangePassword = () => {
    toggleLoading(true);
    if (location.pathname === ROUTES.CHANGE_PASSWORD) {
      toggleLoading(false);
      handleClose();
      return;
    }
    handleClose();
    history.push(ROUTES.CHANGE_PASSWORD);
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && JwtHelper.isValid(user.token)) {
      const fetchBatteryStations = async () => {
        const api = new ApiFactory();
        try {
          const response = await api.get(`/battery-stations`, {
            page: 1,
            pageSize: 1
          });
          setDefaultBatteryStationId(response?.items[0]?.id);
        } catch (error) {}
      };
      fetchBatteryStations();
    }
  }, []);
  const handleChangeRouteId = (route) => {
    toggleLoading(true);
    if (!batteryStationId && !defaultBatteryStationId) {
      setOpenSnackbar(true);
      toggleLoading(false);
    } else {
      history.push(route.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId));
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <React.Fragment>
      <div className={classes.header}>
        <div>
          <Link className={classes.title} to="/">
            {LANG.layout.title}
          </Link>
          <div className={classes.subHeader}>
            <Link to="/" className={classes.subtitle}>
              Storage Battery Reporting System
            </Link>
            <div className={classes.triangle}></div>
            <div className={classes.menuHeader}>
              <ButtonGroup variant="text" aria-label="text button group">
                <Button
                  className={clsx(classes.buttonMenu, location.pathname === ROUTES.BATTERY_STATIONS && classes.buttonMenuActive)}
                  disabled={location.pathname === ROUTES.BATTERY_STATIONS}
                  onClick={() => {
                    history.push(ROUTES.BATTERY_STATIONS);
                  }}
                >
                  {LANG.layout.batteryStations}
                </Button>
                <Button
                  className={clsx(
                    classes.buttonMenu,
                    location.pathname === ROUTES.DASHBOARD.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId) && [
                      classes.buttonMenuActive,
                      classes.displayDashboardButton
                    ]
                  )}
                  disabled={
                    location.pathname === ROUTES.DASHBOARD.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId)
                  }
                  onClick={() => {
                    handleChangeRouteId(ROUTES.DASHBOARD);
                  }}
                >
                  {LANG.layout.realtimeInfo}
                </Button>
                <Button
                  className={clsx(
                    classes.buttonMenu,
                    location.pathname === ROUTES.REPORT.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId) &&
                      classes.buttonMenuActive
                  )}
                  disabled={location.pathname === ROUTES.REPORT.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId)}
                  onClick={() => {
                    handleChangeRouteId(ROUTES.REPORT);
                  }}
                >
                  {LANG.layout.report}
                </Button>
              </ButtonGroup>
              <div className={classes.headerRight}>
                <div className={classes.userName}>{userName}</div>
                <Button onClick={handleClickMenuButton} className={classes.menuButton}>
                  <Settings />
                  <ArrowDropDown />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Button onClick={() => setDrawerOpen(true)} className={classes.menuButtonMobile}>
          <MenuIcon fontSize="large" />
        </Button>
        <div className={classes.break1}></div>
      </div>
      <div className={classes.break2}></div>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        PopoverClasses={{ paper: classes.paper }}
        className={classes.userMenu}
      >
        <MenuItem onClick={redirectChangePassword}>{LANG.layout.changePassword}</MenuItem>
        <MenuItem onClick={() => handleLogout()}>{LANG.layout.logout}</MenuItem>
      </Menu>
      <Sidebar
        drawerOpen={drawerOpen}
        userName={userName}
        drawerToggle={() => setDrawerOpen(false)}
        defaultBatteryStationId={defaultBatteryStationId}
      />
      <CustomSnackbar
        severity="error"
        message="表示できる蓄電所がありません。システム管理者にお問い合わせください。"
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
      />
    </React.Fragment>
  );
};

const mapState = (state) => ({});

const mapDispatch = {
  toggleLoading: toggleLoading
};

export default connect(mapState, mapDispatch)(Header);

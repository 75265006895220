import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Loadable from '../components/Loadable';

// project imports
import MinimalLayout from './../layout/MinimalLayout';
import { ROUTES } from '../constants';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('../views/auth/Login')));
const ForgotPasswordScreen = Loadable(lazy(() => import('../views/forgot-password')));
const ResetPasswordScreen = Loadable(lazy(() => import('../views/reset-password')));
//-----------------------|| AUTHENTICATION ROUTING ||-----------------------//

const AuthenticationRoutes = () => {
  const location = useLocation();

  return (
    <Route exact path={[ROUTES.LOGIN, ROUTES.FORGOT_PASSWORD, ROUTES.RESET_PASSWORD]}>
      <MinimalLayout>
        <Switch location={location} key={location.pathname}>
          <Route exact path={ROUTES.LOGIN} component={AuthLogin} />
          <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordScreen} />
          <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordScreen} />
        </Switch>
      </MinimalLayout>
    </Route>
  );
};

export default AuthenticationRoutes;

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// material-ui
import { makeStyles } from 'tss-react/mui';
import { CssBaseline } from '@mui/material';

// third-party
import { JwtHelper } from '../../helpers/jwt';

// project imports
import Header from './Header';
import Footer from './Footer';
import { ApiFactory } from '../../api/Api';

// style constant
const useStyles = makeStyles()((theme) => ({
  root: {
    minWidth: '376px'
  },
  appBar: {
    backgroundColor: theme.palette.background.default
  },
  appBarWidth: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.background.default
  },
  content: {
    ...theme.typography.mainContent,
    minHeight: 'calc(100vh - 287px)',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 226px)'
    }
  }
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {
  const history = useHistory();
  const { classes } = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const api = new ApiFactory();
        const refreshThreshold = new Date().getTime() + 300000;
        if (!user.credential || !user.token || !JwtHelper.isValid(user.refreshToken)) {
          localStorage.removeItem('user');
          history.push({
            pathname: '/auth/login'
          });
          return;
        }
        if (!JwtHelper.isValid(user.token) || moment(refreshThreshold).isAfter(JwtHelper.expiresAt(user.token))) {
          try {
            const result = await api.post('/auth/grant', {
              refreshToken: user.refreshToken
            });
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...user,
                token: result.token,
                refreshToken: result.refreshToken
              })
            );
            window.location.reload();
          } catch (error) {
            localStorage.removeItem('user');
            history.push({
              pathname: '/auth/login'
            });
          }
        }
      } else {
        localStorage.removeItem('user');
        history.push({
          pathname: '/auth/login'
        });
      }
    };

    fetchData();
  }, [history, user]);
  return (
    <div className={classes.root}>
      {/* main content */}
      <CssBaseline />
      {/* header */}
      <Header userName={user?.credential.name} />
      {/* </Main> */}
      <main className={classes.content}>{children}</main>
      {/* footer */}
      <Footer />
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;

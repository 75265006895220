import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import background from '../../assets/images/background.jpg';
//-----------------------|| MINIMAL LAYOUT ||-----------------------//
const useStyles = makeStyles()((theme) => ({
    container: {
        minWidth: '376px',
        backgroundImage: `url("${background}")`,
        minHeight: '-webkit-fill-available',
        height: '100dvh',
        maxHeight: '-webkit-fill-available',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        padding: '0 8rem 0 8rem',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '0',
            justifyContent: 'center'
        },
        overflow: 'hidden'
    }
}));

const MinimalLayout = (props) => {
    const { classes } = useStyles();
    return (
        <React.Fragment>
            <div className={classes.container}>{props.children}</div>
        </React.Fragment>
    );
};

MinimalLayout.propTypes = {
    children: PropTypes.node
};

export default MinimalLayout;

import React from 'react';

// material-ui
import { makeStyles } from 'tss-react/mui';

// project imports
import LogoImage from '../../../assets/images/shirokuma0410_w.png';

// style constant
const useStyles = makeStyles()((theme) => ({
    footerContainer: {
        paddingTop: '2rem',
        backgroundColor: '#000000',
        color: '#68727D',
        textAlign: 'center',
        paddingBottom: '2rem'
    },
    logo: {
        maxWidth: '170px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%'
    }
}));

//-----------------------|| MAIN NAVBAR / FOOTER ||-----------------------//

const Header = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.footerContainer}>
            <img src={LogoImage} alt="logo" className={classes.logo} />
            <div>Copyright ©ShirokumaPower Co., Ltd. All rights reserved.</div>
        </div>
    );
};

export default Header;

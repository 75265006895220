import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider, CssBaseline, StyledEngineProvider, Backdrop, CircularProgress } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';
import { LANG } from './themes';


// project imports
import NavigationScroll from './layout/NavigationScroll';
import CustomSnackbar from './components/CustomSnackbar';
import { toggleGlobalMessage, toggleGlobalMessageClose, toggleIsOffline } from './redux/_ui.redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

//-----------------------|| APP ||-----------------------//

const App = () => {
  const customization = useSelector((state) => state.ui.customization);
  const snackbar = useSelector((state) => state.ui.snackbar);
  const loading = useSelector((state) => state.ui.loading);
  const isOffline = useSelector((state) => state.ui.isOffline);
  const dispatch = useDispatch();
  const dispatchToggleGlobalMessage = useCallback(
    (open, severity, message) => dispatch(toggleGlobalMessage(open, severity, message)),
    [dispatch]
  );
  const dispatchToggleIsOffline = useCallback((isOffline) => dispatch(toggleIsOffline(isOffline)), [dispatch]);
  const dispatchToggleGlobalMessageClose = useCallback(() => dispatch(toggleGlobalMessageClose()), [dispatch]);
  const history = useHistory();

  useEffect(() => {
    const handleOnline = () => {
      dispatchToggleIsOffline(false);
      window.location.reload();
    };

    const handleOffline = () => {
      dispatchToggleIsOffline(true);
      dispatchToggleGlobalMessage(true, 'error', LANG.error.internetDisconnected);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatchToggleGlobalMessage, dispatchToggleIsOffline]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (isOffline) {
        window.location.reload();
      }
    });
    return unlisten;
  }, [history, isOffline]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(customization)}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
            <CustomSnackbar
              severity={snackbar.severity}
              message={snackbar.message}
              open={snackbar.open}
              handleClose={() => dispatchToggleGlobalMessageClose()}
            />
            <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </NavigationScroll>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

import { createStore as _createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk';
import apiMiddleware from './apiMiddleware';
import { createLogger } from 'redux-logger';
import { auth, PERSIST_DONE } from './_auth.redux';
import { ui } from './_ui.redux';
import { battery } from './_battery.redux';
import { user } from './_user.redux';

const reducer = combineReducers({
  auth,
  ui,
  user,
  battery,
});

export function createStore(api) {
  const getAuthState = () => {
    try {
      const token = localStorage.getItem('auth.token');
      const refreshToken = localStorage.getItem('auth.refreshToken');
      const credential = JSON.parse(localStorage.getItem('auth.credential')) || undefined;
      return { 
        auth: { 
          token,
          refreshToken,
          credential
        }
      }
    } catch (err) {}
  }

  const middlewares = [thunkMiddleware];
  if (process.env.NODE_ENV !== `production`) {
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
  }

  const store = _createStore(
    reducer,
    getAuthState(),
    applyMiddleware(
      ...middlewares,
      apiMiddleware(api)
    )
  )

  store.subscribe(async () => {
    const state = store.getState();
    if(state.auth && state.auth.updated) {
      try {
        // save new token to secure store
        api.token = state.auth?.token;
        localStorage.setItem('auth.token', (state.auth || {}).token);
        localStorage.setItem('auth.refreshToken', (state.auth || {}).refreshToken);
        localStorage.setItem('auth.credential', JSON.stringify((state.auth || {}).credential));
        store.dispatch({type: PERSIST_DONE});
      } catch(e) {
        console.log("Keychain couldn't be accessed!", e);
      }
    }
  });

  return store
}

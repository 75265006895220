export class ApiConst {
    static ERROR = 'API_ERROR';
    static TOKEN_PREFIX = 'Bearer';
    static TOKEN_KEY = 'xtoken';
    static GLOBAL_ACCEPTABLE_ERRORS = [400];

    /**
     * public apis
     */
    static LOGIN = '/auth/login/google';
    static LOGOUT = '/auth/logout';
    static REFRESH_TOKEN = '/auth/grant';

    static BATTERIES = '/batteries';
}

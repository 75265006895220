import React from 'react';

// material-ui
import { makeStyles } from 'tss-react/mui';
import { List, ListItem, ListItemButton, Box, Drawer, Button } from '@mui/material';

// project imports
import { LANG } from '../../../themes';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Close } from '@mui/icons-material';
import CustomSnackbar from '../../../components/CustomSnackbar';
import { useState } from 'react';
import { connect } from 'react-redux';
import { toggleLoading } from '../../../redux/_ui.redux';

// style constant
const useStyles = makeStyles()((theme) => ({
  container: {
    width: '250px',
    height: '100%',
    backgroundColor: '#265CAA',
    color: 'white',
    padding: '0 25px',
    paddingTop: '2rem',
    position: 'relative'
  },
  userName: {
    fontSize: '22px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  list: {
    marginBottom: '2rem'
  },
  listItemButton: {
    color: 'white',
    borderBottom: '1px dashed'
  },
  selectedRoute: {
    backgroundColor: 'white',
    width: '6px',
    height: '25px',
    marginRight: '10px'
  },
  closeButton: {
    color: '#91B2D6',
    position: 'absolute',
    top: 0,
    right: 0
  }
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ drawerOpen, userName, drawerToggle, defaultBatteryStationId, toggleLoading = () => {} }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const { batteryStationId } = useParams();
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleLogout = () => {
    toggleLoading(true);
    localStorage.removeItem('user');
    history.push(ROUTES.LOGIN);
  };

  const handleClick = (route, haveId) => {
    toggleLoading(true);
    if (haveId) {
      if (!batteryStationId && !defaultBatteryStationId) {
        setOpenSnackbar(true);
        toggleLoading(false);
      } else {
        if (location.pathname === route.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId)) {
          drawerToggle();
          return;
        }
        history.push(route.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId));
        drawerToggle();
      }
    } else {
      if (location.pathname === route) {
        drawerToggle();
        return;
      }
      history.push(route);
      drawerToggle();
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={drawerToggle}>
      <Box className={classes.container}>
        <div className={classes.userName}>{userName}</div>
        <List className={classes.list}>
          <ListItem disablePadding>
            <ListItemButton className={classes.listItemButton} onClick={() => handleClick(ROUTES.BATTERY_STATIONS, false)}>
              {location.pathname === ROUTES.BATTERY_STATIONS && <span className={classes.selectedRoute} />}
              <div>{LANG.layout.batteryStations}</div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className={classes.listItemButton} onClick={() => handleClick(ROUTES.DASHBOARD, true)}>
              {location.pathname === ROUTES.DASHBOARD.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId) && (
                <span className={classes.selectedRoute} />
              )}
              <div>{LANG.layout.realtimeInfo}</div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className={classes.listItemButton} onClick={() => handleClick(ROUTES.REPORT, true)}>
              {location.pathname === ROUTES.REPORT.replace(':batteryStationId', batteryStationId ?? defaultBatteryStationId) && (
                <span className={classes.selectedRoute} />
              )}
              <div>{LANG.layout.report}</div>
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <ListItemButton className={classes.listItemButton} onClick={() => handleClick(ROUTES.CHANGE_PASSWORD, false)}>
              {location.pathname === ROUTES.CHANGE_PASSWORD && <span className={classes.selectedRoute} />}
              <div>{LANG.layout.changePassword}</div>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton className={classes.listItemButton} onClick={() => handleLogout()}>
              <div>{LANG.layout.logout}</div>
            </ListItemButton>
          </ListItem>
        </List>
        <Button className={classes.closeButton} onClick={() => drawerToggle()}>
          <Close fontSize="large" />
        </Button>
      </Box>
      <CustomSnackbar
        severity="error"
        message="表示できる蓄電所がありません。システム管理者にお問い合わせください。"
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
      />
    </Drawer>
  );
};

const mapState = (state) => ({});

const mapDispatch = {
  toggleLoading: toggleLoading
};

export default connect(mapState, mapDispatch)(Sidebar);
